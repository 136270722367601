import { Typography } from '@grupo-sbf/motriz-core';
import {
  InfoCircle,
  Lightbulb,
  Download,
} from '@grupo-sbf/motriz-icons/centauro';
import { useRouter } from 'next/router';
import { useState } from 'react';
import TipsModal from '@/common/components/TipsModal/TipsModal';
import { useStrapi } from '@/common/hooks/useStrapi';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import {
  ContainerSubheader,
  Container,
  ContainerIcon,
  ContainerTitle,
  Icon,
  ButtonContainer,
  Tip,
  Button,
} from './Subheader.styled';
import { SubheaderProps } from './Subheader.types';

const Subheader = ({
  breadcrumbList,
  content,
  buttonPrimary,
  buttonSecondary,
}: SubheaderProps) => {
  const [openTipsModal, setOpenTipsModal] = useState(false);
  const { tips, header } = useStrapi();
  const router = useRouter();

  const tipIcon = (tips?.attributes?.tipIcon &&
    {
      lamp: <Lightbulb />,
      infoCircle: <InfoCircle />,
    }[tips.attributes.tipIcon]) || <InfoCircle />;

  const getButtonIcon = (iconType?: 'download' | 'none') => {
    return iconType === 'download' ? <Download /> : undefined;
  };

  const handleButtonClick = (
    buttonType?: 'redirect' | 'others',
    buttonUrl?: string,
    onClick?: () => void,
  ) => {
    if (buttonType === 'redirect' && buttonUrl) {
      router.push(buttonUrl);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <ContainerSubheader data-testid="container-subheader">
      {breadcrumbList && <Breadcrumb breadcrumb={breadcrumbList} />}
      <Container>
        <ContainerTitle>
          <ContainerIcon>
            <Icon>{content?.icon}</Icon>
            <Typography type="subtitle" size="large">
              {content?.title}
            </Typography>
            {tips && (
              <>
                <Tip
                  size="small"
                  icon={tipIcon}
                  variant="tertiary"
                  onClick={() => setOpenTipsModal(true)}
                >
                  {tips.attributes.tipText}
                </Tip>
                <TipsModal
                  isOpen={openTipsModal}
                  onClose={() => setOpenTipsModal(false)}
                  model={tips}
                />
              </>
            )}
          </ContainerIcon>
          {header?.attributes.description && (
            <Typography type="simple" mt={50}>
              {header?.attributes.description}
            </Typography>
          )}
        </ContainerTitle>
        <ButtonContainer>
          {header?.attributes?.buttonSecondaryText && (
            <Button
              onClick={() =>
                handleButtonClick(
                  header?.attributes?.buttonSecondaryType,
                  header?.attributes?.buttonSecondaryURL,
                  buttonSecondary?.onClick,
                )
              }
              icon={getButtonIcon(header?.attributes?.buttonSecondaryIcon)}
              disabled={buttonSecondary?.disabled}
              variant="secondary"
              size="medium"
              fullWidth
            >
              {header?.attributes?.buttonSecondaryText}
            </Button>
          )}
          {header?.attributes?.buttonPrimaryText && (
            <Button
              onClick={() =>
                handleButtonClick(
                  header?.attributes?.buttonPrimaryType,
                  header?.attributes?.buttonPrimaryURL,
                  buttonPrimary?.onClick,
                )
              }
              icon={getButtonIcon(header?.attributes?.buttonPrimaryIcon)}
              disabled={buttonPrimary?.disabled}
              variant="primary"
              size="medium"
              fullWidth
            >
              {header?.attributes?.buttonPrimaryText}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    </ContainerSubheader>
  );
};

export default Subheader;
