import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { getHeader, getTips } from '@/services/strapiJsModule';
import { StrapiData, StrapiHeader, StrapiTips } from '../types/strapi';

export const useStrapi = (): StrapiData => {
  const { data: session } = useSession();
  const router = useRouter();
  const { pathname } = router;

  const { accessToken } = session || {};

  const [header, setHeader] = useState<StrapiHeader>();
  const [tips, setTips] = useState<StrapiTips>();

  useEffect(() => {
    (async () => {
      try {
        if (accessToken) {
          const [headerReq, tipsReq] = await Promise.all([
            getHeader(accessToken, pathname),
            getTips(accessToken, pathname),
          ]);

          setHeader(headerReq[0] as StrapiHeader);
          setTips(tipsReq[0] as StrapiTips);
        }
      } catch {
        console.error('Failed to execute: useStrapi');
      }
    })();
  }, [accessToken, pathname]);

  return { header, tips };
};
