import { Modal, BottomSheet } from '@grupo-sbf/motriz-core';
import { Close } from '@grupo-sbf/motriz-icons';
import { marked } from 'marked';
import { DesktopView } from '@/common/components/DesktopView';
import { MobileView } from '@/common/components/MobileView';
import { Content, Subtitle } from './TipsModal.styled';
import { TipsModalProps } from './types';

const TipsModal = ({ isOpen, onClose, model }: TipsModalProps) => {
  const { title, subtitle, content } = model.attributes;
  const formattedContent = marked(content);

  return (
    <>
      <DesktopView>
        <Modal.Blank isOpen={isOpen} onClose={onClose} title={title}>
          {subtitle && (
            <Subtitle size="medium" type="subtitle">
              {subtitle}
            </Subtitle>
          )}
          <Content
            dangerouslySetInnerHTML={{ __html: formattedContent }}
          ></Content>
        </Modal.Blank>
      </DesktopView>
      <MobileView>
        <BottomSheet
          isOpen={isOpen}
          onClose={onClose}
          onCancel={onClose}
          setIsOpen={onClose}
          closeButton={{
            label: 'fechar',
            icon: <Close />,
          }}
          title={title}
        >
          {subtitle && (
            <Subtitle size="medium" type="subtitle">
              {subtitle}
            </Subtitle>
          )}
          <Content
            dangerouslySetInnerHTML={{ __html: formattedContent }}
          ></Content>{' '}
        </BottomSheet>
      </MobileView>
    </>
  );
};

export default TipsModal;
