import { Typography } from '@grupo-sbf/motriz-core';
import Link from 'next/link';
import { useTheme } from 'styled-components';
import { ContainerBreadcrumb, BreadcrumbIcon } from './Breadcrumb.styled';
import { BreadcrumbProps } from './Breadcrumb.types';

const Breadcrumb = ({ breadcrumb }: BreadcrumbProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    color: { neutral },
  } = useTheme();
  return (
    <ContainerBreadcrumb>
      <ul>
        {breadcrumb.map((item, index) => {
          const isLastItem = index === breadcrumb.length - 1;
          return (
            <li key={item.text}>
              <Typography
                type="simple"
                size="small"
                weight={isLastItem ? 'intense' : 'normal'}
                color={neutral[500]}
              >
                {isLastItem ? (
                  item.text
                ) : (
                  <Link href={item.href} color="#F00">
                    {item.text}
                  </Link>
                )}
              </Typography>
              {!isLastItem && <BreadcrumbIcon />}
            </li>
          );
        })}
      </ul>
    </ContainerBreadcrumb>
  );
};

export default Breadcrumb;
