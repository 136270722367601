import { Typography } from '@grupo-sbf/motriz-core';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    @media (min-width: 720px) {
      border-top: ${theme.borderWidth.xthin} solid ${theme.color.neutral[300]};
    }
    padding-top: ${theme.spacing[300]};
    max-height: 60vh;
  `}
`;

export const Subtitle = styled(Typography)`
  ${({ theme }) => css`
    @media (min-width: 720px) {
      margin-bottom: ${theme.spacing[300]};
    }
    margin-bottom: ${theme.spacing[100]};
  `}
`;
