import { Button as ButtonDS } from '@grupo-sbf/motriz-core';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const ContainerSubheader = styled.div`
  width: 100%;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.medium};
    background: ${theme.color.neutral[100]};
    padding: ${theme.spacing[200]} ${theme.spacing[0]};
  `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('large')} {
    flex-wrap: wrap;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
  `}
`;

export const ContainerTitle = styled.div`
  ${({ theme }) => css`
    ${media.lessThan('large')} {
      margin-bottom: ${theme.spacing[300]};
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.normal};
  `}
`;

export const Icon = styled.div`
  display: flex;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    margin-right: ${theme.spacing[100]};
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    margin-left: ${theme.spacing[300]};
    gap: ${theme.spacing[100]};

    ${media.lessThan('large')} {
      width: 100%;
      margin-left: ${theme.spacing[0]};
      flex-wrap: wrap;
    }
  `}
`;

export const Tip = styled(ButtonDS)`
  text-transform: none;
  text-decoration: underline;

  ${({ theme }) => css`
    gap: ${theme.spacing[50]};
  `};
`;

export const Button = styled(ButtonDS)`
  height: 40px;
  white-space: nowrap;
`;
